import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    domainHovered: "",
})

//Funksjon til domenetekstene på forsiden
export function withDomainText(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        //Bytt ut æøå i domenetaglineså med eoa. Bare for sikkerhetsskyld, hehe
        const domain = props.tagline
            .toLowerCase()
            .replace(/ /g, "")
            .replace("æ", "e")
            .replace("ø", "o")
            .replace("å", "a")

        /*
        console.log(domain)
        
        console.log(props.text)
        console.log(domain)
*/
        //console.log(store.domainHovered)

        return (
            <Component
                {...props}
                variant={
                    //Sjekk om en annen domene-tagline er hovret over.
                    store.domainHovered != domain && store.domainHovered != ""
                        ? "otherHovered" //Hvis et annet domene er hovret over, bytt variant til "otherHovered"
                        : "default" //Hvis ingen andre domener er hovret, bruk varianten "default"
                }
                onMouseEnter={() => setStore({ domainHovered: domain })} //Sett store-verdien til domene-taglinen
                onMouseLeave={() => setStore({ domainHovered: "" })} //Sett store-verdien til blank ved mouse-leave
            />
        )
    }
}

//Funksjon som styrer domenebildene
export function withDomainImg(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        //Bytt ut æøå i domenetaglineså med eoa. Bare for sikkerhetsskyld, hehe
        const domain_text = props.domainText
            .toLowerCase()
            .replace(/ /g, "")
            .replace("æ", "e")
            .replace("ø", "o")
            .replace("å", "a")

        return (
            <Component
                {...props}
                aria-hidden="true" //Sett aria hidden til True så bildet ikke blir selektert på Tab
                style={{
                    ...props.style,
                    //Sett visibility til hidden dersom bildet ikke skal vises.
                    /*visibility:
                        store.domainHovered === domain_text
                            ? "Visible"
                            : "Hidden",*/
                    zIndex: store.domainHovered === domain_text ? 999 : 1,
                }}
                variant={
                    store.domainHovered === domain_text ? "Show" : "Hidden" //Dersom hovret domene er lik domenenavnet, sett bildet til varianten "show". Ellers sett bildet til varianten "hidden"
                }
            />
        )
    }
}

//Funksjon som setter visibility hidden på ting. Brukes for å skjule domene-taglines i bildekomponentene så de ikke leses opp av skjermlesere.
export function withVisibilityHidden(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{ ...props.style, visibility: "hidden" }}
                aria-hidden="true"
            />
        )
    }
}
